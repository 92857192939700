<template>
  <!-- <app-collapse-item title="Opportunities"> -->
  <b-card-actions ref="opportunities" title="List" class="mb-2" no-body no-actions>
    <!-- !! Table Header -->
    <div class="mx-1">
      <b-row>
        <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search"
            @input="doTableFilter(500)"
          />
        </b-col>
        <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2" />
      </b-row>
    </div>
    <!-- Opportunities Table -->
    <b-row class="my-1">
      <b-col cols="12">
        <b-table
          class="mt-2"
          show-empty
          striped
          sticky-header="100%"
          :responsive="true"
          :fields="fields"
          :items="items"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
          @sort-changed="doTableSort"
        >
          <!-- <template #cell(index)="data">
        {{ data.index + 1 }}
         </template> -->
          <template #cell(name)="data">
            <!--            <component-->
            <!--              :is="$can('Access', 'Opportunities') ? 'router-link' : 'span'"-->
            <!--              :to="$can('Access', 'Opportunities') ? `/opportunities/${data.item.id}` : null"-->
            <!--              :class="{ 'text-primary cursor-pointer': !$can('Access', 'Opportunities') }"-->
            <!--              @click="$toast(Toastification.getContentError(-->
            <!--                'You are not authorized! 🔐',-->
            <!--                'You don’t have permission to access this page.'-->
            <!--              ))"-->
            <!--            >-->
            <!--              {{ data.item.name }}-->
            <!--            </component>-->
            <router-link
              :to="`/opportunities/${data.item.id}`"
            >
              {{ data.item.name }}
            </router-link>
          </template>
          <template #cell(last_modified_date)="data">
            {{ formatUsaDate(data.item.last_modified_date) }}
          </template>
          <template #cell(opportunity_name)="data">
            <router-link :to="`/opportunities/${data.item.id}`">
              {{ data.item.opportunity_name }}
            </router-link>
          </template>

          <template #cell(account_name)="data">
            {{ formatAccountName(data.item.account_name) }}
          </template>

          <template #cell(account_owner)="data">
            {{ formatFullName(data.item.account_owner) }}
          </template>

          <template #cell(management_fee)="data">
            {{ formatPercentage(data.item.management_fee) }}
          </template>

          <template #cell(amount)="data">
            <div class="text-right">
              {{ formatMoney(data.item.amount) }}
            </div>
          </template>

          <template #cell(amount_inclusive_of_tax)="data">
            <div class="text-right">
              {{ formatMoney(data.item.amount_inclusive_of_tax) }}
            </div>
          </template>

          <template #cell(salesforce_link_url)="data">
            <a :href="data.item.salesforce_link_url" target="_blank">
              <feather-icon icon="GlobeIcon" class="mr-50" />
            </a>
          </template>
          <template #cell(close_date)="data">
            {{ formatUsaDate(data.item.close_date) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
    <!-- </app-collapse-item> -->
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import DataFormatService from '@/services/DataFormatService'
import moment from 'moment'
import OpportunityFactory from '@/factories/AccountDetails/Opportunity'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import Constant from '@/utils/Constant'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  props: {
    fields: { type: Array, default: new Array([]) },
  },
  data() {
    return {
      showOverlay: true,
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'name',
            key: 'name',
            sortable: true,
            stickyColumn: true,
          },
          {
            label: 'stage',
            key: 'stage',
            sortable: false,
          },
          {
            label: 'account management status',
            key: 'account_management_status',
            sortable: true,
          },
          {
            label: 'amount',
            key: 'amount',
            sortable: false,
          },
          {
            label: 'duration',
            key: 'duration',
            sortable: false,
          },
          {
            label: 'closed date',
            key: 'closed_date',
            sortable: false,
          },
          {
            label: 'account manager',
            key: 'account_manager',
            sortable: false,
          },
          {
            label: 'legal entity',
            key: 'legal_entity',
            sortable: false,
          },
        ],
      },
    }
  },
  created() {
    this.formatDefaultText = DataFormatService.formatDefaultText
    this.formatFullName = DataFormatService.formatFullName
    this.formatMoney = DataFormatService.formatMoney
    this.formatPercentage = DataFormatService.formatPercentage
    this.formatUsaDate = DataFormatService.formatUsaDate
    this.formatAccountName = DataFormatService.formatAccountName
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.opportunities.showLoading = true

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const qparam = {
          perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection, account_id: this.$route.params.id,
        }
        const response = await axiosInstance.get(Constant.apiURL.salesforceAccountOpportunity, { params: qparam })
        if (response.data.status) {
          this.tableConfig.currentPage = Number(response.data.data.current_page)
          this.tableConfig.perPage = Number(response.data.data.per_page)
          this.tableConfig.totalRows = Number(response.data.data.total)
          this.items = OpportunityFactory.createFromJsonArray(response.data.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.tableConfig.currentPage = 1
        this.tableConfig.totalRows = 0
        this.items = []
      } finally {
        if (this.$refs.opportunities) {
          this.$refs.opportunities.showLoading = false
        }
      }
    },
  },
}
</script>
